import React from "react"
import * as styles from "./RichText.module.scss"
import { mergeClassNames } from "../util/util"

interface RichTextProps {
  className?: string,
  text: string
}
export const RichText: React.FC<RichTextProps> = ({text, className}) => {
  return (
    <div
      className={mergeClassNames(className, styles.RichText)}
      dangerouslySetInnerHTML={{__html: text}}
    />
  )
}