import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { IMetadata } from "../../model/IMetadata"
import { IStrapiImageStaticImage } from "../../model/IStrapiImageStaticImage"

interface SEOQueryData {
  site: {
    siteMetadata: {
      siteUrl: string
    }
  },
  strapi: {
    globalConfig: SEOQuery
  }
}

const SEO: React.FC<IMetadata> = (pageSeo) => {
  const { strapi, site: {siteMetadata} } = useStaticQuery<SEOQueryData>(query)

  const {Metadata, titleSuffix, Favicon} = strapi.globalConfig
  const fullSeo = {...Metadata, ...pageSeo};

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle
        },
        {
          property: "twitter:title",
          content: fullSeo.metaTitle
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      )
    }

    if (fullSeo.shareImage) {
      const imageUrl = fullSeo.shareImage.urlSharp.staticURL
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: `${siteMetadata.siteUrl}${imageUrl}`,
        }
      )
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" })

    return tags
  }

  return (
    <Helmet
      title={fullSeo.metaTitle ?? undefined}
      titleTemplate={`%s | ${titleSuffix}`}
      meta={getMetaTags()}
      link={[
        {
          rel: 'icon',
          href: Favicon.urlSharp.staticURL
        }
      ]}
    >
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-P86CV2K')`}
      </script>
    </Helmet>
  )
}

export default SEO

interface SEOQuery {
  titleSuffix: string,
  Favicon: IStrapiImageStaticImage,
  Metadata: IMetadata
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
  
    strapi {
      globalConfig {
        titleSuffix
        Favicon {
          url
          urlSharp {
            staticURL
          }
        }
        Metadata {
          metaTitle
          metaDescription
          shareImage {
            url
            urlSharp {
              staticURL
            }
          }
        }
      }
    }
  }
`
